export function Schedule() {
  const css = `
  /**
  *  Hide parts of google sheets
  */

  .docs-gm #top-bar { 
    display: none !important; 
  }

  .docs-gm tr:last-child  {
    display: none !important;
  }
  `
  return <section>
    <h2>Schedule</h2>
    <iframe title="calendar" id="calendar" height="600" src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=7&amp;bgcolor=%23ffffff&amp;ctz=America%2FNew_York&amp;src=MG5oN3BoMjY5N2phdDlyYWVndDVxOXVmb2NAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;color=%234285F4&amp;showTitle=0&amp;showTabs=0&amp;showNav=0&amp;showCalendars=0&amp;showPrint=0&amp;showTz=0&amp;showDate=0&amp;mode=AGENDA&amp;dates=20230624/20230703
" frameborder="0" scrolling="no">
      <style>{css}</style>
    </iframe>
  </section>;
}