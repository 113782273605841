import './App.css';
import React, { useState, useRef } from 'react';
import {Schedule} from './schedule';
import {Dinner} from './dinner';
import {Bedrooms} from './bedrooms';
import {Weather} from './weather';
import {Panic} from './panic';
import {News} from './news';
import {Map} from './map';
// import {GbFinder} from './gb-finder';

import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC62Ni48IEcc3YbS0VKRxlbCCharkhLRg8",
  authDomain: "prine-family-vacation.firebaseapp.com",
  projectId: "prine-family-vacation",
  storageBucket: "prine-family-vacation.appspot.com",
  messagingSenderId: "160410713081",
  appId: "1:160410713081:web:58d3a734d9addc0d481b28",
  measurementId: "G-5X2P0EWRMB"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const REQUIRE_PASSWORD = false;

function App() {
  const [password, setPassword] = useState(null);
  return (
    <div className="App">
      { REQUIRE_PASSWORD && password !== "topsail" 
       ? <Password setPassword={setPassword} />
       : <MainPage />}
    </div>
  );
}

function Password({setPassword}) {
  const passwordRef = useRef(null);
  return (<div className="App-header password">
    <h1>Password Required</h1>
      <input ref={passwordRef} onKeyUp={() => {
          setPassword(passwordRef.current.value);
      }}></input>

  </div>);
}

function MainPage() {
  return (<div className="main-page"><h1 className="App-header">
    Prine Family Reunion App
    </h1>
    <News />
    <Schedule />
    <Dinner />
    <Bedrooms />
    <Weather />
    <Map />
    {/* <GbFinder /> */}
    <Panic onPanic={() => logEvent(analytics, 'panic_occurred')}/>
  </div>);
}

export default App;
