export function Map() {
  return <section>
    <h2>Local Map</h2>
    <iframe
      title="map"
      height="450"
      frameborder="0" 
      loading="lazy"
      allowfullscreen
      src="https://www.google.com/maps/embed/v1/place?key=AIzaSyC62Ni48IEcc3YbS0VKRxlbCCharkhLRg8
        &q=North+Topsail+Beach,+NC">
    </iframe>
  </section>;
}