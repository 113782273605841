export function Bedrooms() {
    return <section>
      <h2>BEDROOMS</h2>
      <h4>UPSTAIRS</h4>
      <ul>
        <li>Sea Shell Room: Linda</li>
        <li>Seahorse Suite: Alison and Kelley</li>
        <li>Palmette Suite: Nancy and Sherman</li>
        <li>Sandcastle Room: Betsy</li>
        <li>Surf Room & Sunset Room: Nate and Sonoka and kids</li>
      </ul>
      <h4>DOWNSTAIRS</h4>
      <ul>
        <li>Bride Suite: Henry and Caroline</li>
        <li>Sea Room: Sam</li>
        <li>Sea Turtle Room: Elizabeth and Valerie</li>
        <li>Pelican Suite: Dan and Shay</li>
        <li>Starfish Suite: Barb and Paul</li>
        <li>Reef Suite: Jacob and Katie</li>
        <li>Nemo Room: Harper, Brooks, and Martin</li>
        <li>Dolphin Room: Joel</li>
        <li>Coral Room: Janet and Autumn</li>
        <li>Groom Suite: Mabel and Simon</li>
      </ul>
    </section>;
  }