import React from "react";

export function Weather() {
  return (
    <section>
      <h2>Local Weather</h2>
      <a class="weatherwidget-io" href="https://forecast7.com/en/34d49n77d43/north-topsail-beach/?unit=us" data-label_1="NORTH TOPSAIL BEACH" data-label_2="WEATHER" data-font="Monaco" data-icons="Climacons Animated" data-theme="weather_one" >NORTH TOPSAIL BEACH WEATHER</a>
    </section>
  );
}
